<template>
  <b-card-code :title="this.$route.meta.pageTitle">

    <b-row class="mb-2">
        <b-col>
          <b-button-group>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="loading"
                @click="$router.push('/stats/roadents')"
            >
              <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
              />
              <span class="align-middle">Agregar</span>
            </b-button>
          </b-button-group>
        </b-col>
      <b-col class="text-right">
        <b-button-group>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              :disabled="loading"
              @click="onBtExport()"
          >
            <feather-icon
                icon="DownloadIcon"
                class="mr-50"
            />
            <span class="align-middle">Exportar a Excel</span>
          </b-button>
        </b-button-group>
      </b-col>
      </b-row>

    <div class="text-center pt-4" v-if="loading">
      <b-spinner label="Cargando..."/>
      Cargando...
    </div>

    <div v-if="rowData.length > 0">
      <ag-grid-vue
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine custom-grid"
          ref="agGrid"
          @grid-ready="onGridReady"
          @first-data-rendered="onFirstDataRendered"
          domLayout='autoHeight'
          :columnDefs="columnDefs"
          :autoGroupColumnDef="this.gridOptions.autoGroupColumnDef"
          :defaultColDef="this.gridOptions.defaultColDef"
          :excelStyles="this.gridOptions.excelStyles"
          :suppressRowClickSelection="true"
          :groupSelectsChildren="true"
          :pagination="true"
          :paginationPageSize="this.gridOptions.paginationPageSize"
          :rowData="rowData"
          :defaultExcelExportParams="this.gridOptions.defaultExcelExportParams"
          :cacheQuickFilter="true">
        >
      </ag-grid-vue>
    </div>
    <b-row v-if="rowData.length === 0">
      <b-col>
        No se encontraron registros en el rango de fecha.
      </b-col>
    </b-row>


    <!--    Toast-->
    <b-toast id="example-toast" variant="primary">
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-center mr-1">
          <b-img
              src="/logo.jpeg"
              class="mr-1"
              height="18"
              width="18"
              alt="Icon"
          />
          <strong class="mr-auto">Alerta</strong>
        </div>
      </template>
      <span>La fecha <strong>Desde</strong> debe ser menor a la fecha <strong>Hasta</strong>. </span>
    </b-toast>
  </b-card-code>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BButtonGroup,
  BButton, BDropdown, BDropdownItem, BFormDatepicker, BToast, BSpinner, BFormFile, BForm
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'
import {AgGridVue} from "ag-grid-vue";
import environment from "@/environment";
import flatPickr from 'vue-flatpickr-component'
import vSelect from "vue-select";
import {gridDef} from "@/views/stats/ag-grid-conf";
import {format} from "date-fns";


export default {
  name: 'RodenList',
  components: {
    BForm, BFormFile,
    BButtonGroup,
    flatPickr,
    vSelect,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BToast,
    BSpinner,
    BCardCode,
    AgGridVue
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      columnDefs: [
        {
          headerName: "Fecha",
          field: "dateE",
          valueFormatter: (params) => {
            return params.value ? format(new Date(params.value), 'dd-MM-yyyy') : '';
          },
          resizable: true
        },
        { headerName: "Semana", field: "week", resizable: true },
        { headerName: "Región", field: "regionLabel", resizable: true },
        { headerName: "Distrito", field: "districtLabel", resizable: true },
        { headerName: "Corregimiento", field: "corregimientoLabel", resizable: true },
        { headerName: "Localidad", field: "localidadLabel", resizable: true },
        { headerName: "Manz. Trabajadas", field: "manzanas", resizable: true },
        { headerName: "Predios Inspct.", field: "predios", resizable: true },
        { headerName: "Tratados", field: "tratados", resizable: true },
        {
          headerName: 'Factores de Riesgo',
          headerClass: 'headerCenter',
          children: [
            {headerName: "Factores de Riesgo 1", field: "riskOne", resizable: true},
            {headerName: "Factores de Riesgo 2", field: "riskTwo", resizable: true},
            {headerName: "Factores de Riesgo 3", field: "riskThree", resizable: true},
            {headerName: "Factores de Riesgo 4", field: "riskFour", resizable: true},
            {headerName: "Factores de Riesgo 5", field: "riskFive", resizable: true},
            {headerName: "Factores de Riesgo 6", field: "riskSix", resizable: true},
            {headerName: "Factores de Riesgo 7", field: "riskSeven", resizable: true},
          ]
        },

        {
          headerName: 'Presencia de Roedores',
          headerClass: 'headerCenter',
          children: [
            {headerName: "Vivos", field: "roadentsAlive", resizable: true},
            {headerName: "Muertos", field: "roadentsDead", resizable: true},
          ]
        },
        {
          headerName: 'Tipo de Control',
          headerClass: 'headerCenter',
          children: [
            {headerName: "Rodenticida F", field: "rodenticinaF", resizable: true},
            {headerName: "Rodenticida P", field: "rodenticinaP", resizable: true},

            {headerName: "Rodenticida Nº", field: "rodenticinaN", resizable: true},
            {headerName: "Rodenticida S", field: "rodenticinaS", resizable: true},
            {headerName: "Rodenticida DG", field: "rodenticinaDG", resizable: true},
            {headerName: "Rodenticida J", field: "rodenticinaJ", resizable: true},


            {headerName: "Roedores Capturados", field: "capture", resizable: true},
          ]
        },
        {
          headerName: '',
          cellRenderer: params => this.linkEditDocument(params.data._id),
          minWidth: 90
        },
      ],
      rowData: [],
      gridApi: null,
      paginationPageSize: null,
      loading: false,
    };
  },
  computed: {
    rangeFrom() {
      return this.rangeDate.substring(0, 10)
    },
    rangeTo() {
      if (this.rangeDate.length > 11) {
        return this.rangeDate.slice(-10)
      }
    }

  },
  beforeMount() {
    this.paginationPageSize = 50;
    this.paginationNumberFormatter = (params) => {
      return '[' + params.value.toLocaleString() + ']';
    };
    this.gridOptions = gridDef(this.$route.meta.pageTitle)
  },
  mounted() {
    this.searchJsonData()
  },
  methods: {
    linkEditDocument(id) {
      const link = document.createElement('a');
      link.href = `${id}`;
      link.textContent = 'Editar';
      link.className = 'detailsLink';

      return link;
    },
    onBtExport() {
      let params = {
        fileName: `${this.$route.meta.pageTitle}-${this.getCurrentDateTime()}.xlsx`,
        exportMode: 'xlsx',
      };
      console.log(this.gridApi.exportDataAsExcel(params))
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.refreshHeader();
      this.gridApi.refreshCells();
      this.gridApi.setHeaderHeight(24);
    },
    searchJsonData() {
      this.loading = true
      this.$http.get(`${environment.uri}stats/roden`)
          .then(response => {
            this.rowData = response.data;
            this.loading = false
          })
          .catch(error => {
            console.log(error);
            this.loading = false
          })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
@import "@/assets/scss/custom-ag.scss";
@import '@core/scss/vue/libs/vue-select.scss';

</style>








